<template>
    <div class="flex gap-3.5">
        <button
            v-for="slideIndex in numberOfSlides"
            :key="slideIndex"
            class="relative w-10 h-3 group/button-nav"
            @click="handleSetSlide(slideIndex - 1)">
            <div
                :class="[
                    'absolute w-full bottom-1/2 translate-y-1/2 left-0 rounded-none bg-white/40 h-0.5 duration-300 ease-out lg:group-hover/button-nav:h-4/5',
                    'after:absolute after:h-full after:bg-white after:left-0 after:top-0',
                    {
                        'after:animate-progress-width-8s': currentSlideIndex === slideIndex - 1,
                        'after:w-full': currentSlideIndex > slideIndex - 1,
                    },
                ]" />
        </button>
    </div>
</template>

<script setup lang="ts">
import {onMounted, onUnmounted, toRef, watch} from 'vue';
import {wrap} from '@/utils/wrap';

const props = defineProps<{
    numberOfSlides: number;
    currentSlideIndex: number;
}>();

const emit = defineEmits<{
    setSlide: [index: number];
}>();

const interval = 8000;
const currentSlideRef = toRef(props, 'currentSlideIndex');

let slideTimer: ReturnType<typeof setInterval>;

function setTimer() {
    clearInterval(slideTimer);
    slideTimer = setInterval(() => {
        const wrappedIndex = wrap(props.currentSlideIndex + 1, 0, props.numberOfSlides);
        emit('setSlide', wrappedIndex);
    }, interval);
}

function handleSetSlide(index: number) {
    emit('setSlide', index);
    setTimer();
}

watch(currentSlideRef, () => {
    setTimer();
});

onMounted(() => {
    setTimer();
});

onUnmounted(() => clearInterval(slideTimer));
</script>
